import React from 'react';
import { Table } from 'antd';

interface User {
	id: number;
	email: string;
	createdAt: string;
}

interface UserListProps {
	users: User[];
}

export default function UserList({ users }: UserListProps) {
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'Account Creation Date',
			dataIndex: 'createdAt',
		},
	];

	const dataSource = users
		.map((user) => ({
			key: user.id,
			id: user.id,
			email: user.email,
			createdAt: user.createdAt,
		}))
		.sort(
			(a, b) =>
				new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
		);

	return <Table columns={columns} dataSource={dataSource} />;
}

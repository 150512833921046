import React from 'react';
import { Flex } from 'antd';
import Svgs from '../../../../../res/svgs';

interface SatisfactionScoreProps {
	score: number | null;
}

export default function SatisfactionScore({ score }: SatisfactionScoreProps) {
	if (score === null) return null;

	return (
		<Flex align={'center'}>
			<span
				style={{
					backgroundColor: '#F8F9FC',
					borderRadius: '100px',
					fontSize: '18px',
					fontWeight: '500',
					marginRight: '3px',
					paddingRight: '10px',
					paddingLeft: '10px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Svgs.Star /> {score} / 4
			</span>
		</Flex>
	);
}

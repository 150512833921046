import React, { useState } from 'react';
import { Table, Input, TablePaginationConfig } from 'antd';
import { FilterValue, SortOrder, SorterResult } from 'antd/es/table/interface';
import TrendIndicator from '../TrendIndicator';
import { SearchOutlined } from '@ant-design/icons';
import SatisfactionScore from '../SatisfactionScore';
import usePatientData, { Sorter, PatientData } from './hooks/usePatientData';
import dayjs from 'dayjs';

interface PatientDetailsProps {
	data: PatientData[];
}

const getColumns = (sorter: Sorter) => [
	{
		title: 'Patient ID',
		dataIndex: 'patientId',
		key: 'patientId',
		render: (text: string) => <span style={{ color: '#1251FA' }}>{text}</span>,
	},
	{
		title: 'Patient Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Start Date',
		dataIndex: 'startDate',
		key: 'startDate',
		sorter: true,
		sortOrder: sorter.field === 'startDate' ? sorter.order : null,
		render: (text: string) => {
			const formattedDate = dayjs(text).format('M/D/YYYY');
			return <span>{formattedDate}</span>;
		},
	},
	{
		title: 'Time in Program',
		dataIndex: 'programLength',
		key: 'programLength',
		sorter: true,
		sortOrder: sorter.field === 'programLength' ? sorter.order : null,
		render: (text: number) => <span>{text} days</span>,
	},
	{
		title: 'All Metrics Average',
		dataIndex: 'allMetricAverage',
		key: 'avgAll',
		sorter: true,
		sortOrder: sorter.field === 'allMetricAverage' ? sorter.order : null,
		render: (change: number) => <TrendIndicator change={change} />,
	},
	{
		title: 'Core Metrics Average',
		dataIndex: 'coreMetricAverage',
		key: 'avgCore',
		sorter: true,
		sortOrder: sorter.field === 'coreMetricAverage' ? sorter.order : null,
		render: (change: number) => <TrendIndicator change={change} />,
	},
	{
		title: 'Satisfaction Score',
		dataIndex: 'satisfactionScore',
		key: 'satisfactionScore',
		sorter: true,
		sortOrder: sorter.field === 'satisfactionScore' ? sorter.order : null,
		render: (score: number) => <SatisfactionScore score={score} />,
	},
	{
		title: '# of Completed Appointments',
		dataIndex: 'completedAppointments',
		key: 'completedAppointments',
		sorter: true,
		sortOrder: sorter.field === 'completedAppointments' ? sorter.order : null,
	},
	{
		title: '# of No-Shows',
		dataIndex: 'noShowAppointments',
		key: 'noShowAppointments',
		sorter: true,
		sortOrder: sorter.field === 'noShowAppointments' ? sorter.order : null,
	},
];

export default function PatientDetails({ data }: PatientDetailsProps) {
	const [searchText, setSearchText] = useState('');
	const [sorter, setSorter] = useState<Sorter>({
		field: 'startDate',
		order: 'descend',
	});
	const [pagination, setPagination] = useState<TablePaginationConfig>({
		current: 1,
		pageSize: 10,
	});

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
		setSearchText(event.target.value);
	const handleSorterChange = (
		newPagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<PatientData> | SorterResult<PatientData>[],
	) => {
		if (!Array.isArray(sorter)) {
			setSorter({
				field: sorter.field as keyof PatientData,
				order: sorter.order as SortOrder,
			});
		}
		setPagination(newPagination);
	};

	const sortedAndFilteredData = usePatientData(data, searchText, sorter);

	return (
		<div>
			<Input
				placeholder="Search for patient name"
				prefix={<SearchOutlined style={{ color: '#1251FA' }} />}
				value={searchText}
				onChange={handleSearch}
				style={{ marginBottom: 14, width: '100%' }}
			/>
			<Table
				dataSource={sortedAndFilteredData}
				columns={getColumns(sorter)}
				onChange={handleSorterChange}
				pagination={{
					...pagination,
					showSizeChanger: true,
					pageSizeOptions: ['10', '20', '50', '100'],
				}}
			/>
		</div>
	);
}

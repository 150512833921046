import React from 'react';
import styled from 'styled-components';
import Svgs from '../../res/svgs';

function Home() {
	return (
		<HomeContainer>
			<Svgs.OverrideBrand />
		</HomeContainer>
	);
}

export default Home;

const HomeContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

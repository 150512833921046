import React from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface HidePasswordIconProps {
	onClick: () => void;
	passwordHidden: boolean;
}

const HidePasswordIcon = ({
	onClick,
	passwordHidden,
}: HidePasswordIconProps) => {
	return (
		<StyledButton onClick={onClick}>
			{passwordHidden ? <EyeInvisibleOutlined /> : <EyeOutlined />}
		</StyledButton>
	);
};

export default HidePasswordIcon;

const StyledButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin-top: 2.3vh;
	margin-left: -3vw;
	color: lightgray;
	position: absolute;

	@media screen and (max-width: 1200px) {
		font-size: 2em;
		margin-top: 2.2ch;
		margin-left: -100px;
	}
`;

import React from 'react';
import { Tabs, Layout } from 'antd';
import LoginForm from './components/LoginForm';
import { TabItem } from './Analytics.container';

interface AnalyticsComponentProps {
	tabs: TabItem[];
}

export default function AnalyticsComponent({ tabs }: AnalyticsComponentProps) {
	return (
		<Layout style={{ padding: 24 }}>
			<Tabs defaultActiveKey="home" items={tabs} />
			<LoginForm />
		</Layout>
	);
}

import React from 'react';
import { ApolloProvider } from '@apollo/client';
import Home from './home';
import AnalyticsComponent from './Analytics.component';
import ProviderSatisfaction from './provider';
import CurriculumSatisfaction from './curriculum';
import User from './user';
import PatientOutcomes from './outcomes';
import useAuthedApollo from './hooks/useAuthedApollo';

export interface TabItem {
	key: string;
	label: string;
	children: React.ReactNode;
}

const tabs: TabItem[] = [
	{
		key: 'home',
		label: 'Home',
		children: <Home />,
	},
	{
		key: 'curriculum',
		label: 'Curriculum Satisfaction',
		children: <CurriculumSatisfaction />,
	},
	{
		key: 'provider',
		label: 'Provider Satisfaction',
		children: <ProviderSatisfaction />,
	},
	{
		key: 'user',
		label: 'User Information',
		children: <User />,
	},
	{
		key: 'outcomes',
		label: 'Outcomes',
		children: <PatientOutcomes />,
	},
];

export default function AnalyticsContainer() {
	const client = useAuthedApollo();
	return (
		<ApolloProvider client={client}>
			<AnalyticsComponent tabs={tabs} />
		</ApolloProvider>
	);
}

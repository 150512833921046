import { gql, useQuery } from '@apollo/client';

const TAGS_QUERY = gql`
	query {
		_analytics {
			id
			tags
		}
	}
`;

interface Analytics {
	id: string;
	tags: string[];
}

interface TagsQueryData {
	_analytics: Analytics;
}

interface UseTagsVariables {
	[key: string]: string;
}

export default function useTags(variables?: UseTagsVariables) {
	const { data } = useQuery<TagsQueryData>(TAGS_QUERY, { variables });
	return data?._analytics?.tags || [];
}

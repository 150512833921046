import { useMemo, useState } from 'react';
import { cloneDeep, identity } from 'lodash';
import useAnalyticsData from '../../hooks/useAnalyticsData';

const FILTER_ALL_VALUE = 'ALL_VALUES';

interface FilterOption {
	value: string;
	label: string;
}

interface MultiSelectFilter {
	type: 'multiselect';
	name: string;
	options: FilterOption[];
	formatter?: (value: string | string[]) => string | string[];
}

interface SelectFilter {
	type: 'select';
	name: string;
	options: FilterOption[];
	formatter?: (value: string) => string;
}

interface TimeRangeFilter {
	type: 'timeRange';
	keys: string[];
	formatter?: (value: [string, string]) => [string, string];
}

export interface DefaultFilters {
	tags: MultiSelectFilter;
	active: SelectFilter;
	timeInProgram: SelectFilter;
	timeRange: TimeRangeFilter;
	providerId: SelectFilter;
}

const defaultFilters: DefaultFilters = {
	providerId: {
		type: 'select',
		name: 'Provider',
		options: [
			{ value: FILTER_ALL_VALUE, label: 'All' },
			// will get appended here
		],
	},
	tags: {
		type: 'multiselect',
		name: 'Tag',
		options: [
			{ value: FILTER_ALL_VALUE, label: 'All' },
			// tags will get appended here
		],
	},
	active: {
		type: 'select',
		name: 'Status',
		options: [
			{ value: FILTER_ALL_VALUE, label: 'All' },
			{ value: 'active', label: 'Active' },
			{ value: 'inactive', label: 'Inactive' },
		],
	},
	timeInProgram: {
		type: 'select',
		name: 'Time in Program',
		options: [
			{ value: FILTER_ALL_VALUE, label: 'All' },
			{ value: 'less_than_3', label: '0-3 months' },
			{ value: 'between_3_to_6', label: '3-6 months' },
			{ value: 'greater_than_3', label: '3+ months' },
			{ value: 'greater_than_6', label: '6+ months' },
		],
	},
	timeRange: {
		type: 'timeRange',
		keys: ['startDate', 'endDate'],
	},
};

export interface FilterValues {
	tags: string[];
	active: string;
	timeInProgram: string;
	startDate: string;
	endDate: string;
	risk: string;
	providerId: string;
}

const INITIAL_FILTER_VALUES: FilterValues = {
	tags: ['ALL_VALUES'],
	active: 'ALL_VALUES',
	timeInProgram: 'ALL_VALUES',
	startDate: '2020-01-01',
	endDate: '2030-12-31',
	risk: 'ALL_VALUES',
	providerId: 'ALL_VALUES',
};

interface UseSearchFiltersProps {
	tags: string[];
}

interface UseSearchFiltersReturn {
	filterOptions: DefaultFilters;
	filters: FilterValues;
	setFilter: (key: keyof FilterValues, value: string | string[]) => void;
	setFilters: (values: FilterValues) => void;
}

export default function useSearchFilters({
	tags,
}: UseSearchFiltersProps): UseSearchFiltersReturn {
	const { data: providersData } = useAnalyticsData('providers');
	const filterOptions = useMemo(() => {
		const f = cloneDeep(defaultFilters);
		// append tags from backend
		f.tags.options = [
			...f.tags.options,
			...tags.map((tag) => ({ value: tag, label: tag })),
		];

		f.providerId.options = [
			...f.providerId.options,
			...(providersData?.providers
				.slice()
				.sort((a, b) => a.fullName.localeCompare(b.fullName))
				.map((provider) => ({
					value: provider.id,
					label: provider.fullName,
				})) || []),
		];

		return f;
	}, [tags, providersData]);

	// filter values
	const [filters, setFilters] = useState(INITIAL_FILTER_VALUES);
	const setFilter = (key: keyof FilterValues, value: string | string[]) => {
		const transform =
			(defaultFilters[key as keyof DefaultFilters] as any)?.formatter ||
			identity;
		setFilters({
			...filters,
			[key]: transform(value),
		});
	};

	return {
		filterOptions,
		filters,
		setFilter,
		setFilters,
	};
}

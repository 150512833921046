const config = [
	{
		key: 'totalPatients',
		name: 'Number of patients',
		delta: undefined,
		row: 1,
		suffix: undefined,
	},
	{
		key: 'avgImprovement',
		name: 'All metrics - average improvement',
		delta: 'avgImprovementTrend',
		row: 1,
		suffix: '%',
	},
	{
		key: 'avgImprovementCore',
		name: 'Core metrics - average improvement',
		delta: 'avgImprovementCoreTrend',
		row: 1,
		suffix: '%',
	},
	{
		key: 'improvAtleastOneMetric',
		name: 'Patient improvement in at least one metric',
		delta: undefined,
		row: 1,
		suffix: '%',
	},
	{
		key: 'avgSatisfactionScore',
		name: 'Average patient satisfaction',
		delta: undefined,
		row: 2,
		suffix: '%',
	},
	{
		key: 'avgProviderSatisfaction',
		name: 'Average satisfaction w/ providers',
		delta: undefined,
		row: 2,
		suffix: '%',
	},
	{
		key: 'painScore',
		name: 'Pain management - % agree',
		delta: undefined,
		row: 2,
		suffix: '%',
	},
	{
		key: 'decreaseInOpioidUsage',
		name: 'Opiod reduction rate',
		delta: undefined,
		row: 2,
		suffix: '%',
	},
];

export default function useHighlightsData() {
	return {
		config,
	};
}

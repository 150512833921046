import React from 'react';
import { Table } from 'antd';
import groupBy from 'lodash/groupBy';
import SatisfactionSummary from '../components/SatisfactionSummary';
import FeedbackList from '../components/FeedbackList';

interface Rating {
	id: number;
	createdAt: string;
	user: {
		id: number;
		email: string;
	};
	subject: {
		id: number;
		provider: {
			id: number;
			email: string;
			fullName: string;
		};
	};
	rate: number;
	feedback: string;
}

interface ProviderSatisfactionProps {
	ratings: Rating[];
}

interface DataSourceItem {
	id: string;
	key: string;
	email?: string;
	name?: string;
	ratings: Rating[];
}

const getProviderRatings = (ratings: Rating[]): Record<number, Rating[]> => {
	return groupBy(ratings, (rating) => rating.subject.provider.id);
};

const getDataSource = (
	ratingsWithProvider: Rating[],
	providerRatings: Record<number, Rating[]>,
): DataSourceItem[] => {
	return Object.keys(providerRatings).map((id) => {
		const provider = ratingsWithProvider.find(
			(rating) => `${rating.subject.provider.id}` === `${id}`,
		)?.subject.provider;

		return {
			id,
			key: id,
			email: provider?.email,
			name: provider?.fullName,
			ratings: providerRatings[Number(id)],
		};
	});
};

const getColumns = () => {
	return [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Satisfaction',
			render: ({ ratings }: DataSourceItem) => (
				<SatisfactionSummary ratings={ratings} />
			),
		},
		{
			title: 'Comments',
			render: ({ ratings }: DataSourceItem) => (
				<FeedbackList ratings={ratings} />
			),
		},
	];
};

export default function ProviderSatisfaction({
	ratings,
}: ProviderSatisfactionProps) {
	const ratingsWithProvider = ratings.filter(
		(rating) => rating.subject.provider,
	);
	const providerRatings = getProviderRatings(ratingsWithProvider);
	const dataSource = getDataSource(ratingsWithProvider, providerRatings);
	const columns = getColumns();

	return (
		<Table
			columns={columns}
			dataSource={dataSource}
			bordered={true}
			size={'middle'}
		/>
	);
}

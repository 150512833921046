import React from 'react';
import { Flex } from 'antd';
import Svgs from '../../../../../res/svgs';

interface TrendIndicatorProps {
	change: number | null;
}

export default function TrendIndicator({ change }: TrendIndicatorProps) {
	if (change === null) return null;

	const isNegativeChange = change < 0;
	return (
		<Flex align={'center'}>
			<span
				style={{
					color: isNegativeChange ? '#E02D3C' : '#1CB783',
					backgroundColor: isNegativeChange ? '#FEF1F2' : '#EDFDF8',
					borderRadius: '100px',
					fontSize: '18px',
					fontWeight: '600',
					marginRight: '3px',
					paddingRight: '10px',
					paddingLeft: '10px',
				}}
			>
				{change}%{' '}
				{isNegativeChange ? <Svgs.NegativeTrend /> : <Svgs.PositiveTrend />}
			</span>
		</Flex>
	);
}

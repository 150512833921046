import React from 'react';
import { Row, Col } from 'antd';
import Highlight from './components/Highlight';
import { groupBy } from 'lodash';
import { HighlightKeys } from './Highlights.container';

interface HighlightData {
	key?: HighlightKeys;
	name: string;
	delta?: string;
	row: number;
	suffix?: string;
	value: string;
}

interface HighlightsComponentProps {
	data?: HighlightData[];
}

export default function HighlightsComponent({
	data,
}: HighlightsComponentProps) {
	const rows = groupBy(data, 'row');
	return (
		<div>
			{Object.keys(rows).map((rowNum) => (
				<Row
					key={rowNum}
					style={{ margin: '2em', display: 'flex' }}
					gutter={16}
				>
					{rows[rowNum].map((highlight) => (
						<Col key={highlight.name} span={24 / rows[rowNum].length}>
							<Highlight highlight={highlight} />
						</Col>
					))}
				</Row>
			))}
		</div>
	);
}

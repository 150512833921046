import { gql, useQuery } from '@apollo/client';

const OUTCOMES_QUERY = gql`
	query (
		$tags: [String!]!
		$active: ActiveEnum!
		$timeInProgram: TimeInProgramEnum!
		$startDate: DateTime!
		$endDate: DateTime!
		$providerId: String!
	) {
		_analytics {
			id
			patientOutcomes(
				startDate: $startDate
				endDate: $endDate
				risk: ALL_VALUES
				tags: $tags
				active: $active
				timeInProgram: $timeInProgram
				providerId: $providerId
			) {
				subMetrics {
					id
					name
					sortKey
					totalPatients
					percentageImprovedPatients
					improvedPatientsTrend
					positiveAvgImprovement
					totalAvgImprovement
					avgImprovementTrend
				}
				patientMetrics {
					patientId
					name
					programLength
					startDate
					allMetricAverage
					coreMetricAverage
					satisfactionScore
					completedAppointments
					noShowAppointments
				}
				highlights {
					totalPatients
					avgImprovement
					avgImprovementTrend
					avgImprovementCore
					avgImprovementCoreTrend
					improvAtleastOneMetric
					avgSatisfactionScore
					painScore
					avgProviderSatisfaction
					decreaseInOpioidUsage
				}
			}
		}
	}
`;

export interface SubMetric {
	name: string;
	totalPatients: number;
	percentageImprovedPatients: number;
	improvedPatientsTrend: string;
	positiveAvgImprovement: number;
	totalAvgImprovement: number;
	avgImprovementTrend: string;
	key?: string;
	sortKey?: number;
	[key: string]: string | number | undefined;
}

export interface PatientMetric {
	patientId?: string;
	name: string;
	programLength: string;
	startDate: string;
	allMetricAverage: number;
	coreMetricAverage: number;
	satisfactionScore: number;
	completedAppointments: number;
	noShowAppointments: number;
	[key: string]: string | number | undefined;
}

export interface Highlight {
	totalPatients: number;
	avgImprovement: number;
	avgImprovementCore: number;
	improvAtleastOneMetric: number;
	avgSatisfactionScore: number;
	painScore: number;
	avgProviderSatisfaction: number;
	[key: string]: string | number;
}

export const defaultHighlights: Highlight = {
	totalPatients: 0,
	avgImprovement: 0,
	avgImprovementCore: 0,
	improvAtleastOneMetric: 0,
	avgSatisfactionScore: 0,
	painScore: 0,
	avgProviderSatisfaction: 0,
};

interface PatientOutcomes {
	subMetrics: SubMetric[];
	patientMetrics: PatientMetric[];
	highlights: Highlight;
}

interface Analytics {
	id: string;
	patientOutcomes: PatientOutcomes;
}

interface OutcomesQueryData {
	_analytics: Analytics;
}

interface OutcomesQueryVariables {
	tags: string[];
	active: string;
	timeInProgram: string;
	startDate: string;
	endDate: string;
	providerId: string;
}

export default function useOutcomesData(variables: OutcomesQueryVariables) {
	return useQuery<OutcomesQueryData, OutcomesQueryVariables>(OUTCOMES_QUERY, {
		variables,
	});
}

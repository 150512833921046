import React from 'react';
import { Table, Tooltip } from 'antd';
import TrendIndicator from './TrendIndicator';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { SubMetric } from '../hooks/useOutcomesData';

const columns: ColumnsType<SubMetric> = [
	{
		title: 'Metric',
		dataIndex: 'name',
		key: 'name',
		render: (text) => <span style={{ color: '#1251FA' }}>{text}</span>,
		sorter: (a: SubMetric, b: SubMetric) => (a.sortKey || 0) - (b.sortKey || 0),
		defaultSortOrder: 'ascend',
	},
	{
		title: 'N Patients',
		dataIndex: 'totalPatients',
		key: 'totalPatients',
	},
	{
		title: '% Improved Patients',
		dataIndex: 'percentageImprovedPatients',
		key: 'percentageImprovedPatients',
		render: (text) => <span>{text}%</span>,
	},
	{
		title: () => (
			<div>
				Improv Patients Trend
				<Tooltip title="If no time frame is selected, the trend looks at 30 days prior. If a time frame is selected, the trend looks at the number of days prior for the selected time frame (e.g if looking at the last 7 days, the trend will look at 7 days prior) ">
					<InfoCircleOutlined
						style={{ color: '#1251FA', marginLeft: '6px', fontSize: '15px' }}
					/>
				</Tooltip>
			</div>
		),
		dataIndex: 'improvedPatientsTrend',
		key: 'improvedPatientsTrend',
		render: (change) => <TrendIndicator change={change} />,
	},
	{
		title: '% Avg Improvement(Improved)',
		dataIndex: 'positiveAvgImprovement',
		key: 'positiveAvgImprovement',
		render: (text) => <span>{text}%</span>,
	},
	{
		title: '% Avg Improvement(Total)',
		dataIndex: 'totalAvgImprovement',
		key: 'totalAvgImprovement',
		render: (text) => <span>{text}%</span>,
	},
	{
		title: 'Avg Improv Trend',
		dataIndex: 'avgImprovementTrend',
		key: 'avgImprovementTrend',
		render: (change) => <TrendIndicator change={change} />,
	},
];

interface DataTableProps {
	data: SubMetric[];
}

export default function DataTable({ data }: DataTableProps) {
	return <Table style={{ flex: 1 }} dataSource={data} columns={columns} />;
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import ResetPasswordForm from './ResetPasswordForm';
import queryString from 'query-string';

interface QueryParams {
	token?: string;
}

const Form: React.FC = () => {
	const params: QueryParams = queryString.parse(
		window.location.search,
	) as QueryParams;

	return React.createElement(ResetPasswordForm, { token: params.token || '' });
};

function installResetPasswordForm() {
	const div = document.createElement('div');
	div.id = 'reset-password-form';
	document.body.appendChild(div);
	const root = ReactDOM.createRoot(div);
	root.render(React.createElement(Form));
}

declare global {
	interface Window {
		installResetPasswordForm: () => void;
	}
}

window.installResetPasswordForm = installResetPasswordForm;
export default Form;

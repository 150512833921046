const styles = {
	container: {
		flex: 1,
		backgroundColor: '#F8F9FC',
		borderRadius: '10px',
		border: '1px solid #DCE2F7',
		padding: '1em',
		height: '100%',
	},
	heading: {
		color: '#8690B3',
		fontSize: '16px',
	},
	value: {
		fontSize: '34px',
		fontWeight: 'bold',
	},
};

export default styles;

import React from 'react';
import { Flex, Typography } from 'antd';
import TrendIndicator from '../../../TrendIndicator';
import styles from './Highlight.style';
import { HighlightKeys } from '../../Highlights.container';

interface HighlightProps {
	highlight: {
		key?: HighlightKeys;
		name: string;
		delta?: string;
		row: number;
		suffix?: string;
		value: string;
	};
}

export default function Highlight({ highlight }: HighlightProps) {
	return (
		<Flex style={styles.container}>
			<Flex vertical>
				<Typography style={styles.heading}>{highlight.name}</Typography>
				<Flex justify={'space-between'}>
					<Typography style={styles.value}>{highlight.value}</Typography>
					<TrendIndicator
						change={highlight.delta ? parseFloat(highlight.delta) : null}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
}

import React from 'react';
import HighlightsComponent from './Highlights.component';
import useHighlightsData from './hooks/useHighlightsData';
import { Highlight } from '../../hooks/useOutcomesData';

export type HighlightKeys = keyof Highlight;

interface DataPoint {
	key?: HighlightKeys;
	name: string;
	delta?: string;
	row: number;
	suffix?: string;
}

export default function Highlights({ highlights }: { highlights: Highlight }) {
	const { config } = useHighlightsData();

	const data = config.map((dataPoint: DataPoint) => {
		const value = dataPoint.key
			? (highlights[dataPoint.key] as HighlightKeys)
			: undefined;
		return {
			...dataPoint,
			delta: dataPoint.delta
				? (highlights[dataPoint.delta] as string)
				: undefined,
			value: value === undefined ? 'TBD' : value + (dataPoint.suffix || ''),
		};
	});

	return <HighlightsComponent data={data} />;
}

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Form from './components/auth/reset_password';
import AnalyticsContainer from './components/analytics';
import Home from './components/home';

const App: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/auth/reset_password" element={<Form />} />
				<Route path="/analytics" element={<AnalyticsContainer />} />
			</Routes>
		</Router>
	);
};

export default App;

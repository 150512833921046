import { useState } from 'react';

const USERNAME = 'username';
const PASSWORD = 'password';
const AUTHED = 'authed';

function useLocalStorageState(
	key: string,
): [string | null, (v?: string) => void] {
	const [value, _setValue] = useState<string | null>(() =>
		localStorage.getItem(key),
	);
	const setValue = (v?: string) => {
		_setValue(v ?? null);
		if (v === undefined) return localStorage.removeItem(key);
		localStorage.setItem(key, v);
	};
	return [value, setValue];
}

interface LocalStorageAuth {
	authed: string | null;
	setAuthed: (v: string) => void;
	username: string | null;
	password: string | null;
	setCredentials: (username: string, password: string) => void;
	resetAuthState: () => void;
}

export default function useLocalStorageAuth(): LocalStorageAuth {
	const [authed, setAuthed] = useLocalStorageState(AUTHED);
	const [username, setUsername] = useLocalStorageState(USERNAME);
	const [password, setPassword] = useLocalStorageState(PASSWORD);

	function setCredentials(username: string, password: string) {
		setUsername(username);
		setPassword(password);
	}

	function resetAuthState() {
		setAuthed();
		setUsername();
		setPassword();
	}

	return {
		authed,
		setAuthed,
		username,
		password,
		setCredentials,
		resetAuthState,
	};
}

import React from 'react';
import { Flex, Select, Skeleton, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DefaultFilters, FilterValues } from '../hooks/useSearchFilters';
const { RangePicker } = DatePicker;

const filterStyles = { minWidth: 240, marginRight: 10 };

interface FilterConfig {
	key: keyof FilterValues;
	keys?: [keyof FilterValues, keyof FilterValues];
	value?: string;
	values?: [string, string];
	type: 'select' | 'multiselect' | 'timeRange';
	name: string;
	options?: { label: string; value: any }[];
}

interface FilterProps {
	config: FilterConfig;
	setFilter: (key: keyof FilterValues, value: string | string[]) => void;
	filters: FilterValues;
	setFilters: (values: FilterValues) => void;
}

function Filter({ config, setFilter, filters, setFilters }: FilterProps) {
	const { key, keys, value, values, type, name, options } = config;

	switch (type) {
		case 'select':
		case 'multiselect': {
			const optionsWithFullLabel = options?.map((opt) => ({
				...opt,
				label: `${name}: ${opt.label}`,
			}));
			return (
				<Select
					mode={type === 'multiselect' ? 'multiple' : undefined}
					options={optionsWithFullLabel}
					value={value}
					style={filterStyles}
					onChange={(values) => setFilter(key, values)}
				/>
			);
		}
		case 'timeRange': {
			const [start, end] = keys!;
			const rangeValue: [Dayjs | null, Dayjs | null] | null = values
				? [dayjs(values[0]), dayjs(values[1])]
				: null;
			return (
				<RangePicker
					format="YYYY-MM-DD"
					onChange={(_, dates) => {
						const f = {
							...filters,
							[start]: dates[0],
							[end]: dates[1],
						};
						setFilters(f);
					}}
					style={filterStyles}
					value={rangeValue}
				/>
			);
		}
		default:
			return null;
	}
}

interface SearchFiltersProps {
	filters: FilterValues;
	filterOptions: DefaultFilters;
	loading: boolean;
	setFilter: (key: keyof FilterValues, value: string | string[]) => void;
	setFilters: (values: FilterValues) => void;
}

export default function SearchFilters({
	filters,
	filterOptions,
	loading,
	setFilter,
	setFilters,
}: SearchFiltersProps) {
	if (loading) return <Skeleton active />;

	return (
		<Flex>
			{Object.entries(filterOptions).map(([key, config]) => {
				const filterKey = key as keyof FilterValues;
				const { keys } = config;
				return (
					<Flex key={key}>
						<Filter
							config={{
								...config,
								key: filterKey,
								value: filters[filterKey] as string,
								values: keys
									? [filters[keys[0]] as string, filters[keys[1]] as string]
									: undefined,
							}}
							setFilter={setFilter}
							filters={filters}
							setFilters={setFilters}
						/>
					</Flex>
				);
			})}
		</Flex>
	);
}

import React from 'react';
import { Skeleton } from 'antd';
import UserList from './UserInformation.component';
import useAnalyticsData from '../hooks/useAnalyticsData';

export default function UserInformation() {
	const { data, loading } = useAnalyticsData('users');
	if (loading || !data) return <Skeleton active />;
	return <UserList users={data.users} />;
}

import { useMemo } from 'react';
import { SubMetric, PatientMetric } from './useOutcomesData';

interface UseMetricTableDataProps {
	patientOutcomesMetrics: SubMetric[];
}

export default function useMetricTableData({
	patientOutcomesMetrics,
}: UseMetricTableDataProps) {
	return patientOutcomesMetrics.map((metric, index) => ({
		key: `${index}_${metric.name}`,
		...metric,
	}));
}

export const usePatientDetailsTableData = (
	patientOutcomesMetrics: PatientMetric[],
) => {
	return useMemo(() => {
		return patientOutcomesMetrics.map((metric, index) => ({
			key: `${index}_${metric.name}_${metric.patientId}`,
			...metric,
		}));
	}, [patientOutcomesMetrics]);
};

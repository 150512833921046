import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../../../config';

const graphqlUrl = config.graphqlUrl;
const httpLink = createHttpLink({
	uri: graphqlUrl,
});

const authLink = setContext(() => {
	return {
		headers: {
			'internal-auth-user': localStorage.getItem('username'),
			'internal-auth-password': localStorage.getItem('password'),
		},
	};
});

const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
});

export default function useAuthedApollo() {
	return client;
}

import React from 'react';
import PatientOutcomes from './PatientOutcomes.component';
import useTags from './hooks/useTags';
import useSearchFilters from './hooks/useSearchFilters';
import useOutcomesData, { defaultHighlights } from './hooks/useOutcomesData';
import useMetricTableData, {
	usePatientDetailsTableData,
} from './hooks/useMetricTableData';
import { Skeleton } from 'antd';

export default function PatientOutcomesContainer() {
	const tags = useTags();
	const { filterOptions, filters, setFilter, setFilters } = useSearchFilters({
		tags,
	});
	const { data, loading } = useOutcomesData(filters);
	const highlights =
		data?._analytics?.patientOutcomes?.highlights || defaultHighlights;
	const patientsDetailsTableData = usePatientDetailsTableData(
		data?._analytics?.patientOutcomes?.patientMetrics || [],
	);
	const metricTableData = useMetricTableData({
		patientOutcomesMetrics: data?._analytics?.patientOutcomes?.subMetrics || [],
	});

	if (loading || !data) return <Skeleton active />;
	return (
		<PatientOutcomes
			filters={filters}
			filterOptions={filterOptions}
			loadingFilters={loading}
			setFilter={setFilter}
			setFilters={setFilters}
			metricTableData={metricTableData}
			highlights={highlights}
			patientDetailsData={patientsDetailsTableData}
		/>
	);
}

import React, { useState } from 'react';
import { List, Modal, Button, Rate } from 'antd';

export interface Rating {
	rate: number;
	feedback: string;
	user: {
		email: string;
	};
	createdAt: string;
}

interface FeedbackListProps {
	ratings: Rating[];
}

export default function FeedbackList({ ratings }: FeedbackListProps) {
	const [visible, setVisible] = useState(false);
	const feedbacks = ratings.filter((rating) => !!rating.feedback);
	if (!feedbacks.length) return <p>No Data</p>;
	const openModal = () => setVisible(true);
	const closeModal = () => setVisible(false);
	const formatDate = (dateString: string | number | Date) => {
		const date = new Date(dateString);
		const formattedDate = `${date.toLocaleString('default', { month: 'long' })}-${date.getDate()}-${date.getFullYear()}`;
		return formattedDate;
	};
	return (
		<div>
			<Button onClick={openModal}>View {feedbacks.length} Comment(s)</Button>
			<Modal
				title="Modal"
				open={visible}
				onCancel={closeModal}
				footer={[
					<Button key="back" onClick={closeModal}>
						Ok
					</Button>,
				]}
			>
				<List
					bordered
					dataSource={feedbacks}
					renderItem={(rating) => (
						<List.Item>
							<Rate disabled defaultValue={rating.rate} />
							&nbsp;{rating.feedback}
							<p>--- ({rating.user.email})</p>
							<p>--- ({formatDate(rating.createdAt)})</p>
						</List.Item>
					)}
				/>
			</Modal>
		</div>
	);
}

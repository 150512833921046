import { gql, useQuery, DocumentNode } from '@apollo/client';

export default function useAnalyticsData(tab: string) {
	const query = getQuery(tab);
	const { loading, data, error } = useQuery(query || gql``, {
		fetchPolicy: 'network-only',
		skip: !query,
	});

	return {
		loading,
		data: data ? data._analytics : null,
		error: error || (query ? null : new Error('Invalid tab')),
	};
}

function getQuery(tab: string): DocumentNode | null {
	switch (tab) {
		case 'curriculum':
			return CURRICULUM_QUERY;
		case 'ratings':
			return RATINGS_QUERY;
		case 'users':
			return USERS_QUERY;
		case 'providers':
			return PROVIDERS_QUERY;
		default:
			return null;
	}
}

export const CURRICULUM_QUERY = gql`
	query {
		_analytics(ratingsType: Course) {
			__typename
			curriculum {
				id
				courses {
					id
					title
				}
			}
			ratings {
				id
				createdAt
				user {
					id
					email
				}
				subject {
					__typename
					... on Course {
						id
						title
					}
				}
				rate
				feedback
			}
		}
	}
`;

export const USERS_QUERY = gql`
	query {
		_analytics(ratingsType: Course) {
			__typename
			users {
				id
				email
				createdAt
			}
		}
	}
`;

export const RATINGS_QUERY = gql`
	query {
		_analytics(ratingsType: Appointment) {
			__typename
			ratings {
				id
				createdAt
				user {
					id
					email
				}
				subject {
					__typename
					... on InternalAppointment {
						id
						provider {
							id
							email
							fullName
						}
					}
				}
				rate
				feedback
			}
		}
	}
`;

export const PROVIDERS_QUERY = gql`
	query {
		_analytics {
			__typename
			providers {
				id
				fullName
			}
		}
	}
`;

// Home
import { ReactComponent as OverrideBrand } from './svgs/override_brand.svg';

// Outcomes
import { ReactComponent as NegativeTrend } from './svgs/outcomes/negative_trend.svg';
import { ReactComponent as PositiveTrend } from './svgs/outcomes/positive_trend.svg';
import { ReactComponent as Star } from './svgs/outcomes/star.svg';

const Svgs = {
	NegativeTrend,
	OverrideBrand,
	PositiveTrend,
	Star,
};

export default Svgs;

import React from 'react';
import { Table } from 'antd';
import SatisfactionSummary from '../components/SatisfactionSummary';
import FeedbackList from '../components/FeedbackList';

interface Course {
	id: number;
	title: string;
}

interface Rating {
	id: number;
	createdAt: string;
	user: {
		id: number;
		email: string;
	};
	subject: {
		id: number;
	};
	rate: number;
	feedback: string;
}

interface Curriculum {
	courses: Course[];
}

interface CurriculumSatisfactionComponentProps {
	curriculum: Curriculum;
	ratings: Rating[];
}

export default function CurriculumSatisfactionComponent({
	curriculum,
	ratings,
}: CurriculumSatisfactionComponentProps) {
	const ds = curriculum.courses
		.map((fa) => ({
			key: fa.id,
			ratings: ratings.filter((rating) => rating.subject.id === fa.id),
			...fa,
		}))
		.sort((a, b) => a.id - b.id);

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Title',
			dataIndex: 'title',
		},
		{
			title: 'Satisfaction',
			render: (row: { ratings: Rating[] }) => {
				return <SatisfactionSummary ratings={row.ratings} />;
			},
		},
		{
			title: 'Comments',
			render: (row: { ratings: Rating[] }) => {
				return <FeedbackList ratings={row.ratings} />;
			},
		},
	];

	return <Table columns={columns} dataSource={ds} />;
}

import React from 'react';
import { Flex } from 'antd';
import SearchFilters from './components/SearchFilters';
import Highlights from './components/Highlights';
import DataTable from './components/DataTable';
import PatientDetails from './components/PatientDetailsSummary';
import { DefaultFilters, FilterValues } from './hooks/useSearchFilters';
import { Highlight, PatientMetric, SubMetric } from './hooks/useOutcomesData';

interface PatientOutcomesProps {
	filterOptions: DefaultFilters;
	loadingFilters: boolean;
	filters: FilterValues;
	setFilter: (key: keyof FilterValues, value: string | string[]) => void;
	setFilters: (values: FilterValues) => void;
	metricTableData: SubMetric[];
	patientDetailsData: PatientMetric[];
	highlights: Highlight;
}

export default function PatientOutcomes({
	filterOptions,
	loadingFilters,
	filters,
	setFilter,
	metricTableData,
	patientDetailsData,
	highlights,
	setFilters,
}: PatientOutcomesProps) {
	return (
		<Flex vertical style={{ maxWidth: '1200px', margin: '0 auto' }}>
			<Flex justify={'flex-start'}>
				<SearchFilters
					filters={filters}
					filterOptions={filterOptions}
					loading={loadingFilters}
					setFilter={setFilter}
					setFilters={setFilters}
				/>
			</Flex>
			<Flex>
				<Highlights highlights={highlights} />
			</Flex>
			<Flex>
				<DataTable data={metricTableData} />
			</Flex>
			<Flex style={{ width: '100%' }}>
				<PatientDetails data={patientDetailsData} />
			</Flex>
		</Flex>
	);
}

import React from 'react';
import useAnalyticsData from '../hooks/useAnalyticsData';
import ProviderSatisfaction from './ProviderSatisfaction.component';
import { Skeleton } from 'antd';

export default function ProviderSatisfactionConatiner() {
	const { data, loading } = useAnalyticsData('ratings');
	if (loading || !data) return <Skeleton active />;
	return (
		<ProviderSatisfaction
			ratings={data.ratings.filter(
				(r: { subject: { __typename: string } }) =>
					r.subject?.__typename === 'InternalAppointment',
			)}
		/>
	);
}

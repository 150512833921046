import { useMemo } from 'react';

export interface Sorter {
	field: keyof PatientData;
	order: 'ascend' | 'descend' | null;
}

export interface PatientData {
	patientId?: string;
	name: string;
	startDate: string;
	programLength: string;
	allMetricAverage: number;
	coreMetricAverage: number;
	satisfactionScore: number;
	completedAppointments: number;
	noShowAppointments: number;
}

const usePatientData = (
	data: PatientData[],
	searchText: string,
	sorter: Sorter,
): PatientData[] => {
	return useMemo(() => {
		const filteredData = searchText
			? data.filter((item) => {
					const name = item.name || '';
					return name.toLowerCase().includes(searchText.toLowerCase());
				})
			: data;

		return filteredData.sort((a, b) => {
			const isAsc = sorter.order === 'ascend';
			const aValue = a[sorter.field];
			const bValue = b[sorter.field];
			if (aValue === undefined || bValue === undefined) {
				return 0;
			}
			if (aValue < bValue) return isAsc ? -1 : 1;
			if (aValue > bValue) return isAsc ? 1 : -1;
			return 0;
		});
	}, [data, searchText, sorter]);
};

export default usePatientData;

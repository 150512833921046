import React from 'react';
import { gql } from '@apollo/client';
import { Button, Form, Input, notification } from 'antd';
import useAuthedApollo from '../hooks/useAuthedApollo';
import useLocalStorageAuth from '../hooks/useLocalStorageAuth';

const AUTH_QUERY = gql`
	query {
		_analytics {
			authed
		}
	}
`;

interface LoginFormValues {
	username: string;
	password: string;
}

export default function LoginForm() {
	const client = useAuthedApollo();
	const { resetAuthState, setCredentials, authed, setAuthed } =
		useLocalStorageAuth();
	const [notify, contextHolder] = notification.useNotification();

	const onAuthFailed = () => {
		notify.open({
			message: 'Failed to authenticate.',
			description: '',
			duration: 1000,
		});
		resetAuthState();
	};

	const onFinish = ({ username, password }: LoginFormValues) => {
		setCredentials(username, password);

		client
			.query({ query: AUTH_QUERY })
			.then((res) => {
				const hasAuthed = res.data?._analytics?.authed;
				hasAuthed ? setAuthed(hasAuthed) : onAuthFailed();
			})
			.catch(onAuthFailed);
	};

	return (
		<>
			{contextHolder}
			{authed && (
				<Button type="primary" onClick={() => setAuthed('false')}>
					Logout
				</Button>
			)}
			{!authed && (
				<Form
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600, marginTop: '2em' }}
					initialValues={{ remember: true }}
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.Item
						label="Username"
						name="username"
						rules={[{ required: true, message: 'Please input your username!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: 'Please input your password!' }]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
						<Button type="primary" htmlType="submit">
							Login
						</Button>
					</Form.Item>
				</Form>
			)}
		</>
	);
}

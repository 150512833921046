import React from 'react';
import { Skeleton } from 'antd';
import CurriculumSatisfactionComponent from './CurriculumSatisfaction.component';
import useAnalyticsData from '../hooks/useAnalyticsData';

export default function CurriculumSatisfaction() {
	const { data, loading } = useAnalyticsData('curriculum');
	if (loading || !data) return <Skeleton active />;

	return (
		<CurriculumSatisfactionComponent
			curriculum={data.curriculum}
			ratings={data.ratings.filter(
				(r: { subject: { __typename: string } }) =>
					r.subject?.__typename === 'Course',
			)}
		/>
	);
}

import React, { useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import client from '../../../graphql/apollo/apolloClient';
import HidePasswordIcon from '../HidePasswordIcon';
import Svgs from '../../../res/svgs';

interface ResetPasswordFormProps {
	token: string;
}

interface ResetPasswordVariables {
	password: string;
	passwordConfirmation: string;
	token: string;
}
function resetPassword(variables: ResetPasswordVariables) {
	return client.mutate({
		mutation: gql`
			mutation (
				$password: String!
				$passwordConfirmation: String!
				$token: String!
			) {
				userUpdatePasswordWithToken(
					password: $password
					passwordConfirmation: $passwordConfirmation
					resetPasswordToken: $token
				) {
					__typename
				}
			}
		`,
		variables,
	});
}

function ResetPasswordFrom({ token }: ResetPasswordFormProps) {
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [passwordUpdateCompleted, setPasswordUpdateCompleted] = useState(false);
	const [passwordHidden, setPasswordHidden] = useState(true);
	const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
	const [shouldSubmitForm, setShouldSubmitForm] = useState(true);

	const passwordMatching = password === passwordConfirmation;
	const submitable = password && passwordConfirmation && passwordMatching;
	const onResetPassword = (e: { preventDefault: () => void }) => {
		e.preventDefault();

		if (!shouldSubmitForm) {
			setShouldSubmitForm(true);
			return;
		}

		if (!submitable) {
			if (!password) {
				setErrMsg('Password is required.');
			} else if (!passwordConfirmation) {
				setErrMsg('Confirm Password is required.');
			} else if (!passwordMatching) {
				setErrMsg('Password and Confirm Password do not match.');
			}
			return;
		}

		resetPassword({ password, passwordConfirmation, token })
			.then((res) => {
				if (res?.data?.userUpdatePasswordWithToken?.__typename) {
					setPasswordUpdateCompleted(true);
				}
			})
			.catch((e) => setErrMsg(e.message));
	};

	const togglePasswordVisibility = (field: string) => {
		if (field === 'password') {
			setPasswordHidden(!passwordHidden);
		} else if (field === 'confirmPassword') {
			setConfirmPasswordHidden(!confirmPasswordHidden);
		}

		setShouldSubmitForm(false);
	};

	return (
		<StyledForm onSubmit={onResetPassword}>
			<Svgs.OverrideBrand />
			{!passwordUpdateCompleted && <p className={'error'}>{errMsg}</p>}
			<h1>
				{passwordUpdateCompleted ? `New password set!` : `Set New Password`}
			</h1>
			{!passwordUpdateCompleted && (
				<div>
					<input
						type={passwordHidden ? 'password' : 'text'}
						onChange={(e) => setPassword(e.target.value)}
						placeholder={'Password'}
					/>
					<HidePasswordIcon
						passwordHidden={passwordHidden}
						onClick={() => togglePasswordVisibility('password')}
					/>
				</div>
			)}
			{!passwordUpdateCompleted && (
				<div>
					<input
						type={confirmPasswordHidden ? 'password' : 'text'}
						onChange={(e) => setPasswordConfirmation(e.target.value)}
						placeholder={'Confirm Password'}
					/>
					<HidePasswordIcon
						passwordHidden={confirmPasswordHidden}
						onClick={() => togglePasswordVisibility('confirmPassword')}
					/>
				</div>
			)}
			{!passwordUpdateCompleted && (
				<input type="submit" value="Reset Password" />
			)}
		</StyledForm>
	);
}

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2em;
	min-width: 500px;

	h1 {
		text-align: center;
		font-size: 1.3em;
	}

	.error {
		text-align: center;
		color: #0cbcf6;
	}

	img {
		max-width: 300px;
		margin: 1em auto;
	}

	input[type='password'],
	input[type='text'] {
		border-radius: 5em;
		padding: 1em;
		border: 1px solid #dadada;
		margin: 0.3em;
		min-width: 500px;
	}

	input[type='submit'] {
		background-color: #0cbcf6;
		border: 1px solid #dadada;
		border-radius: 5em;
		color: white;
		padding: 1em;
		margin-top: 1em;
		cursor: pointer;
		width: 100%;
	}

	input[type='submit']:disabled {
		background-color: lightgray;
	}

	@media screen and (max-width: 1200px) {
		display: flex;
		padding: 0 5em;
		flex-direction: column;
		justify-content: center;

		img {
			width: 40vw;
			max-width: none;
		}

		h1 {
			font-size: 3em;
		}

		input {
			font-size: 2em;
		}
	}
`;

export default ResetPasswordFrom;

import React from 'react';
import { mean } from 'lodash';
import { Rate, Tag } from 'antd';
import { Rating } from './FeedbackList';

interface SatisfactionSummaryProps {
	ratings: Rating[];
}

export default function SatisfactionSummary({
	ratings,
}: SatisfactionSummaryProps) {
	if (!ratings.length) return <p>No Data</p>;
	const ratingNums = ratings.map((ratingObj) => ratingObj.rate);
	const avg = mean(ratingNums);
	return (
		<div>
			<Rate disabled defaultValue={avg} /> &nbsp;
			<Tag>{avg.toPrecision(2)} / 5</Tag>
			<Tag>{ratings.length} reviews</Tag>
		</div>
	);
}
